import React, { useState, useEffect, useRef } from 'react';
import { useCart } from "react-use-cart";
import { Link } from 'react-router-dom';
import Ariane from './Ariane';
import BottomNavigation from './BottomNavigation';
import Repeatable from 'react-repeatable'; 
import DataTable from 'react-data-table-component';
import bs5Styles from '../assets/themes/bs5';
import notfound from '../assets/images/notfound.png';
import { strings } from '../localization';

const getDiscountPrice = (regular_price, discount) => {
    return Math.round((regular_price - (regular_price * discount/100))*100)/100 
}
const isDiscounted= (qty, discount) => {
    return discount && qty >= discount.min
}
const getProductPackUnit = (id, defaultVal) => {
    return sessionStorage.getItem('products.productPackUnit.' + id) ? parseInt(sessionStorage.getItem('products.productPackUnit.' + id)) : defaultVal;
}


export default function Cart() {
    const {
      isEmpty
    } = useCart();
  
    
  
    return (        
        <div className="products h-100 d-flex flex-column justify-content-between">
            <Ariane>
            <h1>{ strings.details_commande }</h1>
            </Ariane>
            <div className='container-xxl flex-grow-1 '>
                { isEmpty ? 
                <div className="alert alert-dark"><i className="fa fa-shopping-bag me-3"></i>{ strings.panier_vide }</div>
                :
                <CartItems noTableHead={false} />}
            </div>
            <BottomNavigation className="justify-content-end">
                
                <div className='col-4'>
                    <MiniCart />
                </div>
            </BottomNavigation>
        </div>
    );

}

function formatCurrency(number, locale = 'fr-FR'){
    
    return new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(number)
}

function CartItems(props){

    const {
        items,
        updateItem,
        removeItem,
        cartTotal,
    } = useCart();   
    
    const paginationComponentOptions = {
        rowsPerPageText: strings.articles_page,
        rangeSeparatorText:  strings.articles_of,
        selectAllRowsItem: true,
        selectAllRowsItemText: strings.articles_all,        
        
    };

    
    

    return (
        <>
        <DataTable
            columns={[ 
                { 
                    name: <i className='fa fa-image'></i>,
                    selector: row => row.image, 
                    format: row => <Link to={ "/product/"+row.id }>{ row.image ? <img src={ row.image } alt="" className="img-fluid img-cover" /> : <img src={ notfound } alt="" className="img-fluid img-cover" />}</Link>, 
                    compact: true, 
                    center: true, 
                    width:'50px',
                },
                { name: strings.cart_table_title, selector: row => row.name },
                { name: strings.cart_table_qty, selector: row => row.quantity, format: row =>  <QuantitySelector locked="true" layout="horizontal" updateQuantity={ (qty) => updateItem(row.id, { quantity : qty, price : isDiscounted(qty, row?.discount) ? getDiscountPrice(row.regular_price, row?.discount.amount) : row.regular_price }) } packUnit={ getProductPackUnit(row.id, row.pack_unit) } quantity={ row.quantity } />, center: true, width: '200px'},
                { name: strings.cart_table_price, selector: row => row.price, format: row => isDiscounted(row.quantity, row?.discount) ? <><s className='me-3 small'>{ formatCurrency( row.regular_price ) }</s><span className='text-success'>{ formatCurrency(row.price) }</span></> : <>{ formatCurrency(row.price) }</>, right: true, width:'150px'},
                { name: strings.cart_table_total, selector: row => row.itemTotal, format: row => formatCurrency(row.itemTotal), right: true, width:'150px'},
                { name: <i className='fa fa-trash'></i>, selector: row =>  row.id, format: row =>  <button onClick={() => removeItem(row.id)} className="btn btn-primary text-white"><i className='fa fa-times'></i></button>, center: true, width: '100px'}
                
            ]}
            data={items}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationPerPage={ 10 }
            noTableHead={ props.noTableHead }
            striped={ true }
            theme="bs5"
            customStyles={ bs5Styles }
            noDataComponent={ <div className='alert alert-warning w-100 m-0'><i className="fa fa-exclamation-triangle me-3"></i>Aucune article dans le panier</div> }
        /> 
        { ! props.hideTotal && 
            <table className='table table-striped table-primary'>
                <tbody>
                    <tr className='d-flex'>
                        <td className='flex-grow-1 p-3 text-end'>
                            <strong>{ strings.total } :</strong>
                        </td>
                        <td className='p-3'>
                            { formatCurrency( cartTotal ) }
                        </td>
                    </tr>
                    <tr className='d-flex'>
                        <td className='flex-grow-1 p-3 text-end'>
                           
                        </td>
                        <td className='p-3'>
                            <Link className='btn btn-primary' to='/order/new'><i className="fa fa-plus-circle me-3"></i>{ strings.nouvelle_commande }</Link>
                        </td>
                    </tr>
                </tbody>
            </table>
        } 
        </>     
    )
}

CartItems.defaultProps = {
    noTableHead: true,
  }
  

function MiniCart(){

    const {        
        totalItems
    } = useCart();
    
    return (
        <Link to="/cart"  className='cart text-end d-block text-white'>
            
            <span className='shopping-cart fs-1'>
                { totalItems }
                <i className='fa fa-shopping-bag mx-3'></i>
            </span>
        </Link>
    )

}


function QuantitySelector(props){

    const [ qty, setQty ] = useState(1)
    const [ min, setMin ] = useState()
    const [ max, setMax ] = useState()
    const [ toggled, setToggled ] = useState()
   
    useEffect(() => {
        setQty(props.quantity)
        setMin(props.min)
        setMax(props.max)
        setToggled(props.toggled)
    },[props])
    

    const changeQty = (value) => {


        let newQty = parseInt(value);  

        if( ! newQty || newQty < 0)
        newQty = 0;

        const modulo = newQty % props.packUnit;

        
        newQty = newQty >= props.packUnit ? newQty - modulo : ( ! props.locked ? 0 : props.packUnit);
        
        if( (!min || newQty >= min) && (! max || newQty <= max) )
        {
            setQty(newQty)

            if(!newQty)
            setToggled(false)
    
            props.updateQuantity(newQty);
        }
       
    }

    const useOutsideAlerter = (ref, inputRef) => {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if(inputRef.current)
                changeQty(inputRef.current.value)
                setToggled(false)
            }
          }
          if(!props.locked)
          document.addEventListener("mousedown", handleClickOutside);

          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref,inputRef]);
    }

    const ref = useRef(null);
    const inputRef = useRef(null)
    
    useOutsideAlerter(ref,inputRef);

   

    const buttonClasses = "btn btn-primary d-block text-white" + ( props.layout === "horizontal" ? ''  : " w-100" )
    const InputWrapperClasses = props.layout === "horizontal" ? "flex-grow-1" : ""

    

    
    return (

        <div ref={ ref } className='cart-qty'>
            { ! toggled && ! props.locked
            ?
            <button type="button" onClick={ () => setToggled(true) } className="btn btn-primary d-block text-white w-100">
                { qty  }
            </button>
            :
            <div className={ (props.layout === "horizontal" ? "d-flex " : '') + "cart-update-qty"} onClick={ e => e.stopPropagation() }>
                <Repeatable 
				repeatDelay={0}
                repeatInterval={450}
				onHold={ () => changeQty(qty - props.packUnit) }
				
				>
                    <button type='button' className={ buttonClasses } >-</button>
                </Repeatable>
                <div className={ InputWrapperClasses }>
                    <input ref={ inputRef } type="text" className="bg-white border-1 border-primary text-primary border-0 p-3 fw-bold text-center w-100" onChange={ event => { setQty(event.target.val) }} onBlur={ (event) =>  changeQty(event.target.value) } value={ qty }  />
                </div>
                <Repeatable 
				repeatDelay={0}
                repeatInterval={450}
				onHold={ () => changeQty(qty + props.packUnit) }
				
				>
                    <button type='button' className={ buttonClasses } >+</button>
                </Repeatable>
                
            </div>
            }
        </div>
    )
}

QuantitySelector.defaultProps = {
    locked: false,
    toggled: false,
    packUnit: 1,
    min: false,
    max:false,
  }
  
export { MiniCart, QuantitySelector, CartItems, formatCurrency, getDiscountPrice, isDiscounted };