import React, { useState, useEffect, useRef } from 'react';
import BottomNavigation from '../components/BottomNavigation'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useApi } from '../components/Api';
import { EffectCreative } from 'swiper';
import { useParams, Link, useNavigate} from 'react-router-dom';
import { CartItems, formatCurrency } from '../components/Cart';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners'
import { useCustomFields } from './CustomFields';
import moment from 'moment';
import DataTable, { createTheme } from 'react-data-table-component';
import bs5Styles from '../assets/themes/bs5';
import { useCart } from "react-use-cart";
import { useIndexedDB } from 'react-indexed-db';
import { strings } from '../localization';
import { confirmAlert } from 'react-confirm-alert'; // Import




const validate = values => {

    const errors = {}
    
    if( ! values.customer)
    errors.customer = "Le client est obligatoire"

    if( ! values.shipping_address)
    errors.shipping_address = "L'adresse de livraison est obligatoire"

    
    return errors

}

const useOrder = () => {

    const [ orderTypes, setOrderTypes ] = useState([])
    const [ orderStatus, setOrderStatus ] = useState([])
    const [ orderPorts, setOrderPorts ] = useState([])
    

    useEffect( () => {

        const localizedOrderTypes = [ 
            {value:"0", label: strings.order_type_0 },
            {value:"1", label: strings.order_type_1 },
            {value:"2", label: strings.order_type_2 },
            {value:"3", label: strings.order_type_3 },
            {value:"4", label: strings.order_type_4 },
            {value:"5", label: strings.order_type_5 },
            {value:"6", label: strings.order_type_6 },
            {value:"7", label: strings.order_type_7 },
        ]

        setOrderTypes(localizedOrderTypes)

        const localizedOrderStatus = [
            {value:"0", label:strings.order_status_0}, // NOUVELLE
            {value:"1", label:strings.order_status_1}, // VALIDEE
            {value:"2", label:strings.order_status_2}, // ENVOYE
            {value:"3", label:strings.order_status_3}, // SYNCHRONISE
        ]

        setOrderStatus(localizedOrderStatus)

        const localizedOrderPort = [
            {value:"1", label:strings.order_port_1}, // Franco de port
            {value:"2", label:strings.order_port_2}, // Avancé
        ]

        setOrderPorts(localizedOrderPort)


    },[])

    return  {
        'orderTypes': orderTypes,
        'orderStatus' : orderStatus,
        'orderPorts': orderPorts,
    }  
    
}






const OrderForm = (props) => {

    const [ activeIndex, setActiveIndex] = useState(0)
    const swiperRef = useRef();
    const [user, { setUser }]  = useApi();
    let { orderRef } = useParams()
    const { getAll, update } = useIndexedDB('catalog');
    const [ order, setOrder] = useState(null)
    const [ orders, setOrders] = useState([])
    const [ customers, setCustomers] = useState([])    
    const [addresses, setAddresses] = useState([])
    const [ formChanges, setFormChanges ] = useState(false)
    const [ saved, setSaved ] = useState(false)
    const [ selectedDeliveryWeek, setSelectedDeliveryWeek ] = useState(order ? order.shipping_week : 0)

    const { items, cartTotal, emptyCart, addItem, inCart, getItem, updateItemQuantity, removeItem } = useCart()

    const signature = useRef()
    
    const navigate = useNavigate()

    const { orderTypes, orderStatus, orderPorts } = useOrder()

    const [ canEdit, setCanEdit ] = useState(false);
     
    useEffect(() => {

        getAll().then(dataFromDB => {
            let foundOrders = dataFromDB.find(i => i.type === 'orders')?.data ?? [];       
            let foundCustomers = dataFromDB.find(i => i.type === 'customers')?.data ?? [];             
            let foundOrder = foundOrders.find((o) => o.ref === orderRef)  
            
            
         
            
            setOrders(foundOrders)
            setCustomers(foundCustomers)

            if(foundOrder?.signPath && signature.current)
            signature.current.loadPaths(foundOrder.signPath)        

            
            if(foundOrder){
                foundOrder.numLignes = foundOrder.items.length
                setOrder(foundOrder)     
                let customer = foundCustomers.find(c => c.ref === foundOrder.customer)
                let newAddresses = customer?.addresses.map(a => { return {value:a.ref, label:a.address1}})
                setAddresses(newAddresses)    
            }else
            setOrder({ref:'',type:1,status:0,items:[],date:moment().unix(),shipping_address:null, shipping_date:moment().add(1, 'M').unix(),customer:null,sign: null, signPath: null, numLignes:items.length, port: 1, shipping_week:0})         
             
            

        })  

        if(canEdit)
        {
            order.items.forEach(item => {
                addItem({ 
                    id:item.ref,
                    price: item.price,
                    name: item.title,
                    image: item.image,
                    pack_unit : item.pack_unit ,
                    regular_price : item.regular_price,
                    discount:item.discount 
                },
                item.quantity)
            })
        }

        
    }, [canEdit]);

   

  

    const handleEditItems = () => {    
        
        confirmAlert({
            title: 'Modifier une commande',
            message: 'Le panier en cours sera effacé, êtes-vous sur de vouloir continuer ?',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    emptyCart()
                    setCanEdit(true)     
                }
              },
              {
                label: 'Annuler',
                onClick: () => {
                    
                }
              }
            ]
        });
          
        
    }

       
    
    if(order && ! formChanges){

        const diffArray = (arr1, arr2) => {
            const diff = (a, b) => {
              return a.filter(item => b.indexOf(item) === -1);
            }
          
            var diff1 = diff(arr1, arr2)   
            var diff2 = diff(arr2, arr1)  
            return diff1.concat(diff2) 
          
        }

        const cartItems = items.map(item => item.id)
        const orderItems = order.items.map(item => item.ref)
        const diff = diffArray(cartItems,orderItems)

        if(diff.length){
           
            setFormChanges(true)
        }
        
    }
  


    const handleSave = async (values) => {        
        setCanEdit(false)          

        let updated_order = { ...order, ...values }  

        updated_order.items = items.map( (item, index) => {
            return {
                item_order: (index+1)*1000,
                ref: item.id,
                title: item.name,
                quantity: item.quantity,
                regular_price: item.regular_price,
                price: item.price,
                discount: item?.discount,
                image: item.image,
                pack_unit: item.pack_unit
            }
        })

        updated_order.shipping_week = selectedDeliveryWeek;
        updated_order.numLignes =  updated_order.items.length 
        updated_order.total = cartTotal;

        if(! updated_order.ref )
        updated_order.ref = "NO"+user.ref+Date.now();

        if( updated_order.status ){
            
            await signature.current.exportImage('png').then(data => {                
                updated_order.sign = data;
            })
            .catch(e => {
                console.log(e);
            });
        }
        else
        {
            await signature.current.exportPaths().then(data => {
                console.log(data)
                updated_order.signPath = data
            })
            .catch(e => {
                console.log(e);
            });
        }

        

        let newOrders = orders.map(o => 
            o.ref === updated_order.ref ? updated_order : o
        )    
        
        if(! newOrders.find(o => o.ref === updated_order.ref))
        newOrders.push(updated_order);

        update({type: 'orders', data: newOrders}).then(event => {

            console.log('saved',updated_order)
            setFormChanges(false)
            setOrder(updated_order)
            setSaved(true)
            setUser({...user, order_num: user.order_num+1})
            if(updated_order.status){
                emptyCart();
                navigate('/order/'+updated_order.ref)
            }
        }) 
    }

    const locale = 'fr-FR'   
    const [ { RenderInput, RenderDate, RenderTextArea, RenderCustomerField, RenderSelect} ] = useCustomFields()
    const formatDate = (value,name) => { 
        if (typeof value === 'object') {
            return value.date ? moment.unix(value.date).format('D-M-Y') : '' ;
        }
        return value ? moment.unix(value).format('D-M-Y') : '' ;
    };
    const parseDate = (value,name) => { 
        if (name === 'shipping_date') {
            setSelectedDeliveryWeek(0);
        }
        if (typeof value === 'object') {
            if (name === 'shipping_date' && value.week !== undefined) {
                setSelectedDeliveryWeek(value.week);
            }
            return value.date ? moment(value.date,'D-M-Y').unix() : ''
        }
        return value ? moment(value,'D-M-Y').unix() : ''
    };
    const products =  JSON.parse(localStorage.getItem('products'))    
    const formatType = (value, name) => value ? orderTypes.find(t => t.value === value.toString()) : '';
    const parseType = (value, name) => value ? value.value : '';
    const formatAddress = (value,name) =>  addresses?.find(a => a.value === value) ?? ''; 
    const parseAddress = (value,name) => value ? value.value : '' ;
    const formatPort = (value, name) => value ? orderPorts.find(t => t.value === value.toString()) : '';
    const parsePort = (value, name) => value ? value.value : '';
    const handleSignatureChange = () => {
        setFormChanges(true)
    }
    
    let submit;

    const paginationComponentOptions = {
        rowsPerPageText: strings.articles_page,
        rangeSeparatorText: strings.articles_of,
        selectAllRowsItem: true,
        selectAllRowsItemText: strings.articles_all,
        
    };

    
    return (
   
    <Form
      onSubmit={handleSave}
      validate={validate}
      initialValues={ order }   
      mutators={{
        handleValidate: (args,state,utils) => {
            utils.changeValue(state, 'status', () => 1)
        },
        handleUpdateAddresses : (args, state, utils) => { 
            utils.changeValue(state, 'shipping_address', () => args?.[0]?.[0] ?? '')
        }
      }}

      
      render={({ handleSubmit, form, submitting, pristine, values, dirty }) => {

        submit = handleSubmit
        return (
        <>
        <form onSubmit={handleSubmit} className="orders-form">
          <div className='container-lg'>
            <div className='row d-block'>
              <Swiper ref={ swiperRef } spaceBetween={100} onActiveIndexChange={ (swiper) => setActiveIndex(swiper.activeIndex)} effect="creative"
                            creativeEffect={{
                                prev: {
                                    shadow: true,
                                    translate: [0, 0, -100],
                                    opacity: 0
                                },
                                next: {
                                    translate: [0, "100%", 0],
                                    opacity: 0
                                },
                            }}
                            modules={ [ EffectCreative ] } 
                            allowTouchMove={ false }
                            simulateTouch={ false } 
                             >
                <SwiperSlide className='border-1 border-white border p-3 bg-primary'>     
                  <div className='row'>
                    <Field name="ref" label={ strings.num_commande }  readonly component={ RenderInput } /> 
                    <Field name="numLignes" label={ strings.lignes_commande } readonly classes={ {label:'col-1 text-end',input:'col-2'}} component={ RenderInput } />
                    <Field name="type" label={ strings.type_commande} parse={ parseType } format={ formatType } classes={ {label:'col-1 text-end',input:'col-3'}} placeholder={ strings.choisissez_etat } component={ RenderSelect } options={ order?.status ? orderTypes : orderTypes.filter(t => ["0","1"].includes(t.value)) } />
                  </div>
                  <div className='row mt-3'>
                    <Field name="customer" customFormat={ (value,name) => { let customer = customers?.find(c => c.ref === value ); return customer ? { value:customer.ref, label:customer.company} : ''} } customParse={ (value,name) => value ? value.value : ''} label={ strings.client_commande} styles="z-index:1000;"  classes={ {label:'col-2',input:'col-6'}} component={ RenderCustomerField } placeholder={ strings.rechercher_client } options={ customers?.map(c => { return {value:c.ref, label: c.company} }) } />
                    <OnChange name="customer">
                    {(value, previous) => {
                        const customer = customers.find(c => c.ref === value)
                        const newAddresses = customer ? customer?.addresses.map(a => { return {label:a.address1, value:a.ref}}) : []
                        setAddresses(newAddresses)
                        //form.mutators.handleUpdateAddresses(newAddresses)
                        
                    }}
                    </OnChange>
                    <Field name="shipping_address"  label={ strings.addresse_commande} parse={ parseAddress } format={ formatAddress } classes={ {label:'col-1 text-end',input:'col-3'}} placeholder={ strings.choisissez_adresse } component={ RenderSelect } options={ addresses } />
                  </div>
                  <div className='row mt-3'>
                      <Field name="date" label={ strings.date_commande} parse={ parseDate } format={ formatDate } classes={ {label:'col-2',input:'col-2'}} placeholder="Selectionnez une date" component={ RenderDate } />
                      <Field name="shipping_date" label={ strings.date_livraison} parse={ parseDate } format={ formatDate } classes={ {label:'col-2 text-end',input:'col-2'}} placeholder="Selectionnez une date" weekNum={ order?.shipping_week ? order.shipping_week : selectedDeliveryWeek } withWeekNumber={true} component={ RenderDate } />
                      <Field name="port" label={ strings.port_commande} parse={ parsePort } format={ formatPort } classes={ {label:'col-1 text-end',input:'col-3'}} placeholder={ strings.choisissez_port } component={ RenderSelect } options={ order?.port ? orderPorts : orderPorts.filter(t => ["1","2"].includes(t.value)) } />
                  </div>
                  <div className='row mt-3'>
                    <Field name="comment" label={ strings.commentaire } classes={ {label:'col-2', input:'col-10'}} component={ RenderTextArea } />
                  </div> 
                </SwiperSlide>
                <SwiperSlide className='border-1 border-white border p-3 bg-primary products'> 
                        {   order?.ref  && ! canEdit
                            ? 
                                <>
                                    { ! order?.status &&
                                    <div className='text-end pb-3'>
                                        <button className='btn btn-secondary' onClick={ handleEditItems }>
                                            <i className='fa fa-pencil-alt'></i> { strings.edit_order }
                                        </button>
                                    </div>}                                    
                                    <DataTable
                                        data={order.items}
                                        columns={ [

                                            {
                                                name:strings.order_item_table_lines,
                                                selector: row => row.item_order,
                                                right: true,
                                                width: '100px',
                                                sortable: true,
                                            },
                                            {
                                                name: <i className='fa fa-image'></i>,
                                                selector: row => row.ref,
                                                format: row => {
                                                    let product = products?.find(p => p.ref === row.ref)
                                                    return product && product.images.length ? <div className="img-wrapper"><img src={ product.images[0] } className="img-cover" /></div> : ''
                                                },
                                                center: true,
                                                compact: true,
                                                width:'50px',
                                            },
                                            {
                                                name: strings.ref,
                                                selector: row => row.ref,
                                                width: '150px',
                                                center: true,
                                                sortable: true,
                                            },                                        
                                            {
                                                name:strings.order_item_table_title,
                                                selector: row => row.title,
                                                sortable: true,
                                            },
                                            {
                                                name:strings.order_item_table_qty,
                                                selector: row => row.quantity,
                                                right: true,
                                                width: '125px',
                                                sortable: true,
                                            }
                                            ,
                                            {
                                                name:strings.order_item_table_price,
                                                selector: row => row.price,
                                                format: row => formatCurrency(row.price),
                                                right: true,
                                                width: '125px',
                                                sortable: true,
                                            }
                                        ]}
                                        highlightOnHover                                    
                                        pagination                                    
                                        paginationPerPage={ 5 }
                                        striped={ true }
                                        theme="bs5"
                                        customStyles={ bs5Styles }
                                        paginationComponentOptions={paginationComponentOptions}
                                        className="mb-3"
                                    />
                                </>
                            :
                            <div>
                                <div className='d-flex justify-content-center'>
                                    <Link className="btn btn-secondary mb-3" to="/products">
                                        <i className="fa fa-search me-3"></i>{ strings.rechercher_articles }
                                    </Link>
                                </div>
                                <CartItems hideTotal={ true } />       
                            </div>
                                                 
                        }
                        <table className='table table-striped table-primary'>
                            <tbody>
                                <tr className='d-flex'>
                                    <td className='flex-grow-1 p-3 text-end'>
                                        <strong>{ strings.total } :</strong>
                                    </td>
                                    <td className='p-3'>
                                        { formatCurrency( order?.ref && ! canEdit ? order.total : cartTotal ) }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </SwiperSlide>
                <SwiperSlide className='border-1 border-white border p-5 bg-primary'>               
                  { order?.status    ?
                    ( order.sign ? <img src={ order.sign } className="img-fluid" /> : <div className='alert'>{ strings.signature_aucune }</div> )
                  :
                    <Signature canvasPath={ order?.signPath } ref={ signature } handleChange={ handleSignatureChange } /> }
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </form>
        <BottomNavigation className="justify-content-around">
          <button type="button" onClick={ () => {props.updateTitle(strings.order_details); swiperRef.current?.swiper.slideTo(0)} } className={ (swiperRef.current?.swiper.activeIndex === 0 ? "active " : "" ) + "btn btn-lg col-auto" }><i className='fs-1 fa fa-clone me-3'></i>{ strings.informations }</button>
          <button type="button" onClick={ () => {props.updateTitle(strings.details_commande); swiperRef.current?.swiper.slideTo(1)} } className={ (swiperRef.current?.swiper.activeIndex === 1 ? "active " : "" ) + "btn btn-lg col-auto" }><i className='fs-1 fa fa-list me-3'></i>{ strings.details }</button>
          <button type="button" onClick={ () => {props.updateTitle(strings.signature_client); swiperRef.current?.swiper.slideTo(2)} } className={ (swiperRef.current?.swiper.activeIndex === 2 ? "active " : "" ) + "btn btn-lg col-auto" }><i className='fs-1 fa fa-signature me-3'></i>{ strings.signature }</button>
          <button type="button" disabled={ order?.status  || ( ! dirty && ! formChanges ) } onClick={ submit } className={ "btn btn-lg col-auto" + (order?.status || (saved && !dirty && !formChanges) ? ' text-success' : '') }>{ order?.status || (saved && !dirty && !formChanges) ? <><i className='fs-1 fa fa-check me-3'></i> { strings.saved }</> : <><i className='fs-1 fa fa-save me-3'></i> { strings.enregistrer }</> }</button>
          <button type="button" disabled={ order?.status  } onClick={ () => { form.mutators.handleValidate(); form.submit(); } } className={ "btn btn-lg col-auto" + ( order?.status ? ' text-success' : '' ) } ><i className='fs-1 fa fa-check-circle me-3'></i>{ order?.status ? strings.confirmed : strings.confirmation }</button>
        </BottomNavigation>
        </>
        )}}
    />
  )
}

const Signature = React.forwardRef( (props, ref) => {

  const styles = {
    border: '0.0625rem solid #9c9c9c',
    borderRadius: '0.25rem',
  }

 
  return (
    <ReactSketchCanvas
      style={styles}
      height="400px"
      ref={ ref }
      strokeWidth={4}
      strokeColor="black"
      onChange={ props.handleChange }
      
    />
  )

});


export default OrderForm;

export { useOrder };