import { forwardRef } from 'react';
import { Field } from 'react-final-form';
import Select from "react-select";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { format, isValid, parse } from "date-fns";
import theme from "../assets/scss/theme.scss";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import { read } from '@popperjs/core';

registerLocale('fr', fr);
const countryList = require('country-list');
//const regionNamesInLocaleLanguage = new Intl.DisplayNames(fr, { type: 'region' });




const selectStyles = {
    control: (styles) => ({ ...styles, color:theme.secondary }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles, backgroundColor: isFocused ? theme.secondary : 'white', color: isFocused ? 'white' : theme.secondary
        }
    }

}


function useCustomFields(){

    const createRenderer = render => ({ input, meta, label, placeholder, readonly, classes = {label:'col-2',input:'col-3'}, ...rest }) => {
        
        if(classes)
        return (
            <>            
            {
            label !== false && label !== null &&   
            <label className={ "form-label  mb-3 text-start " + classes.label }>{ label }</label>
            }            
            <div className={ "mb-3 " + classes.input }>
                { render(input, label, placeholder, readonly,classes, meta, rest) }
            </div> 
            </>
        )
        else
        return render(input, label, placeholder, readonly,classes, meta, rest)
    }

    
    
    
    const RenderInput = createRenderer((input, label, placeholder, readonly,classes, meta, { maxLength } ) => 
        <input {...input} type="text" maxLength={ maxLength ?? '' }  readOnly={ readonly }  className={ 'form-control' + ( meta.touched  && ! readonly && (meta.error || input.value != '') ? ' is-' + ( meta.error ? 'in' : '' )+ 'valid' : '') } disabled={ readonly ? ' disabled' : '' } placeholder={ placeholder ? placeholder : ( label ? label : '' ) } />
    )
    
    const RenderCheckbox = createRenderer((input, label, placeholder, readonly,classes, meta, { maxLength } ) => 
    <div className='form-check text-start'>
        <input {...input} type="checkbox" id="duplicate" maxLength={ maxLength ?? '' }  readOnly={ readonly }  className={ "form-check-input " + ( meta.touched  && ! readonly && (meta.error || input.value != '') ? ' is-' + 'invalid' : '') } disabled={ readonly ? ' disabled' : '' }  />
        <label className="form-check-label" htmlFor="duplicate">
            { placeholder ? placeholder : ( label ? label : '' ) }
        </label>
    </div>)


    const RenderSelect = createRenderer((input, label, placeholder, readonly, classes, meta, { noOptionsMessage, options }) => {        
       
        return (
            <Select isClearable={ true } {...input} placeholder={ placeholder }  styles={ selectStyles } className={ "custom-select" + ( meta.touched  && ! readonly ? ' border border-2 ' + 'border-'+( meta.error ? 'danger' : 'success' ) : '')} noOptionsMessage={ noOptionsMessage } options={ options } />
        )

    })

    const handleChangeRaw = (onChange,date) => {        
        const newRaw = new Date(date.currentTarget.value);        
        
        if (newRaw instanceof Date && !isNaN(newRaw)) {            
            onChange(date);
        }
    };

    const handleWeekSelection = (onChange, date, week) => {
        const newWeek = new Date(date);

        if (newWeek instanceof Date && !isNaN(newWeek)) {
            onChange({date: format(new Date(date), "dd-MM-yyyy"), week: week});
        }

    }

    const DatePickerInput = forwardRef(({ onClick, value, onChange }, ref) => (
        
        <div className="input-group date" >
            <input type="text" className="form-control" onChange={ onChange } onFocus={onClick} value={ value } ref={ref} />
            <span className="input-group-append">
                <span className="input-group-text text-secondary d-block" onClick={onClick} >
                <i className="fa fa-calendar"></i>
                </span>
            </span>
        </div>

    ))
    

    const formatCountry = (value, name) => { 
        
        try{ 
            //const countryName = regionNamesInLocaleLanguage.of(value); 
            return {label: value, value:value } 
        } catch{ 
            return value 
        } 
    }

    const parseCountry = (value, name) =>  value ? value.value : ''

    const RenderCountryField = createRenderer((input,label, placeholder, readonly, classes, meta) => {
       
        return <Field {...input} format={ formatCountry } parse={ parseCountry } label={ false }  classes={ false } component={ RenderSelect }  placeholder="Choisissez un pays" options={ countryList.getData().map(c => { return { label: c.name+" ("+c.code+")", value: c.code } }) }  noOptionsMessage={ ({ inputValue: string }) => "Aucun pays"}  />
     })

     
    
    
    const RenderDate = createRenderer(( input, label, placeholder, readonly,classes, meta, { weekNum, withWeekNumber }) => {
        return <div>
        <DatePicker customInput={ <DatePickerInput /> }  onChangeRaw={ (e) => handleChangeRaw(input.onChange,e) } showWeekNumbers locale="fr" placeholderText={ placeholder ? placeholder : ( label ? label : '' ) } dateFormat="dd-MM-yyyy" selected={ input.value && isValid(parse(input.value, "dd-MM-yyyy", new Date())) ? new Date(parse(input.value, "dd-MM-yyyy", new Date())) : null } name={input.name} onChange={(date) => {
    
        if (isValid(date)) {
            input.onChange(format(new Date(date), "dd-MM-yyyy"));
        } else {
            input.onChange(null);
        }
    
        }} 
        onWeekSelect={(...obj) => {
            handleWeekSelection(input.onChange, obj[0], obj[1]);
        }} className={ ( meta.touched ? ' is-' + ( meta.error ? 'in' : '' ) + 'valid' : '') } />
        { withWeekNumber && weekNum ? <span>Semaine {weekNum}</span> : '' }
        </div>
    })
    
    const RenderTextArea = createRenderer((input, label, placeholder, readonly,classes, meta, { children, maxLength }) => 
        <textarea {...input} maxLength={ maxLength ?? '' } className='form-control' rows="4" />
    )

    const formatCustomer = (value,name) => value ? { value: value.ref, label: value.company } : '';
    const parseCustomer = (value,name) => value ? { ref: value.value, company: value.label } : '';
    
    const RenderCustomerField = createRenderer((input, label, placeholder, readonly,classes, meta, { options, customFormat, customParse }) => {
        
      
       
        return <div className="row">
        <div className='col-md-4'>
            <input className='form-control' name="customer_ref" disabled="disabled"  defaultValue={ input.value?.ref ?? input.value } />
        </div>
        <div className='col-md-8'>
            <Field {...input}  label={ false } format={ customFormat ?? formatCustomer } parse={ customParse ?? parseCustomer }  classes={ {} } component={ RenderSelect }  placeholder={ placeholder } options={ options } noOptionsMessage={ ({ inputValue: string }) => "Aucun client"}  />
        </div>
        </div>
        
    
    })

    

    return [
        {
            'RenderInput': RenderInput,
            'RenderCheckbox': RenderCheckbox,
            'RenderSelect': RenderSelect,
            'RenderDate': RenderDate,
            'RenderTextArea': RenderTextArea,
            'RenderCustomerField': RenderCustomerField,
            'RenderCountryField' : RenderCountryField,
            'selectStyles' : selectStyles
        } 
    ]
        

}


export { useCustomFields, selectStyles };